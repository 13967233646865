@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --border-radius: 0.25rem;
    --base-spacing: 0.5rem;

    --neutral-white: #ffffff;
    --neutral-black: #000000;
    --neutral-background: #f9fbfb;
    --neutral-700-opac: #141414B3;
    --neutral-700: #141414;
    --neutral-600: #434343;
    --neutral-500: #727272;
    --neutral-400: #a1a1a1;
    --neutral-300: #d0d0d0;
    --neutral-200: #e0e0e0;
    --neutral-100: #f2f2f2;
    --neutral-bl-100: #eef2f7;

    --success-800: #072713;
    --success-700: #0e4f26;
    --success-600: #147638;
    --success-500: #1b9e4b;
    --success-400: #22c55e;
    --success-300: #4ed17e;
    --success-200: #7adc9e;
    --success-100: #a7e8bf;
    --success-50: #d3f3df;

    --error-800: #250505;
    --error-700: #4a0a0a;
    --error-600: #701010;
    --error-500: #951515;
    --error-400: #ba1a1a;
    --error-300: #c84848;
    --error-200: #d67676;
    --error-100: #e3a3a3;
    --error-50: #f1d1d1;
    --error-10: #faf0f0;

    --tertiary-800: #301f0f;
    --tertiary-700: #613d1e;
    --tertiary-600: #915c2c;
    --tertiary-500: #c27a3b;
    --tertiary-400: #f2994a;
    --tertiary-300: #f5ad6e;
    --tertiary-200: #f7c292;
    --tertiary-100: #fad6b7;
    --tertiary-50: #fcebdb;
    --tertiary-10: #fdf2e7;

    --secondary-800: #180030;
    --secondary-700: #30005f;
    --secondary-600: #47008f;
    --secondary-500: #5f00be;
    --secondary-400: #7700ee;
    --secondary-300: #9233f1;
    --secondary-200: #ad66f5;
    --secondary-100: #c999f8;
    --secondary-50: #e4ccfc;
    --secondary-10: #f2e7fe;

    --primary-800: #07211e;
    --primary-700: #0d423c;
    --primary-600: #14645a;
    --primary-500: #1a8578;
    --primary-400: #21a696;
    --primary-300: #4db8ab;
    --primary-200: #7acac0;
    --primary-100: #a6dbd5;
    --primary-50: #d3edea;
    --primary-10: #edf8f6;

    --logo: #0bd6c2;
  }
}

@layer base {
  * {
    @apply border-neutral-200;
  }
  body {
    @apply text-neutral-700;
  }
}
